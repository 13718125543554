body {
    background-color: white;
}

.shortMessagePanel {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.singleMessagePanel {
    display: flex;
    gap: 20px;
    height: 50vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row-status--pending {
    background-color: #b2b2ff;
}
.row-status--result-verified {
    background-color: #d4f7ff;
}
.row-status--import-fail {
    background-color: #ffc0cb;
}

.row-status--error {
    background-color: #ffc0cb;
}

.row-status--sending {
    background-color: #ffedaa;
}

.row-status--completed {
    background-color: #ccffcc;
}

.TopMenu {
    border-bottom: 1px solid #ccc;
}
.TopMenu a {
    text-transform: none;
}

.wip {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 3em;
}

.filterbox {
    display: flex;
    gap: 1em;
}
